import React from 'react';
import Navbar from './navbar.js';
import Footer from './footer.js';

function Contact() {
  return (
    <>
      <Navbar />
      <h1>Contact</h1>
      <Footer />
    </>
  );
}
export default Contact;
